import {handleKeyPressEnterOrSpace, getAvatars, getExtraGuestsCount, isRsvp} from '@wix/wix-events-commons-statics'
import classNames from 'classnames'
import * as _ from 'lodash'
import * as React from 'react'
import {AvatarGroup, AvatarGroupSize} from 'wix-ui-tpa/AvatarGroup'
import {DH} from '../../utils/data-hooks'
import {classes} from './members.st.css'
import {MembersProps} from './index'

const MAX_AVATARS = 3

export const Members = ({
  event,
  onImage,
  singleLayout,
  calendarLayout,
  listLayout,
  onClick,
  t,
  showMembers,
}: MembersProps) => {
  const {members} = event
  const totalGuests = _.isEmpty(event.totalGuests) ? 0 : event.totalGuests
  const items = getAvatars(members, totalGuests, isRsvp(event), MAX_AVATARS, showMembers)
  const extraGuests = getExtraGuestsCount(totalGuests, MAX_AVATARS)

  const handleClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation()
    onClick(event.id)
  }

  let avatarsClass = classes.avatarsDefault

  if (onImage) {
    avatarsClass = classes.avatarsOnImage
  } else if (singleLayout) {
    avatarsClass = classes.avatarsSingleLayout
  } else if (calendarLayout) {
    avatarsClass = classes.avatarsCalendarLayout
  } else if (listLayout) {
    avatarsClass = classes.avatarsListLayout
  }

  return (
    <div
      onClick={handleClick}
      onKeyPress={handleKeyPressEnterOrSpace(handleClick)}
      tabIndex={0}
      aria-label={t('a11y.guests')}
      className={classes.root}
    >
      <AvatarGroup
        {...{className: classNames(classes.avatars, avatarsClass)}}
        maxAmount={MAX_AVATARS}
        items={items}
        size={AvatarGroupSize.small}
        data-hook={DH.members}
      >
        {extraGuests ? (
          <AvatarGroup.TextButton tabIndex={-1} className={classes.avatar}>
            {t('members.moreLink', {amount: extraGuests})}
          </AvatarGroup.TextButton>
        ) : null}
      </AvatarGroup>
    </div>
  )
}
