import {getFullLocale, isEditor, isPreview} from '../../../../commons/selectors/environment'
import {
  getCardBorderWidth,
  getCardMargins,
  getImageRatio,
  getListAlignment,
  getListImageOpacity,
  getcardLocationAndDateFormat,
} from '../../../../commons/selectors/settings'
import {getRibbonData} from '../../../selectors/events'
import {isItemOpened} from '../../../selectors/layout'
import {
  isAdditionalComponentsHidden,
  isDateVisible,
  isDescriptionVisible,
  isFullDateVisible,
  isImageVisible,
  isLocationVisible,
  isMembersEnabled,
  isMembersVisible,
  isSocialShareVisible,
  isVenueVisible,
} from '../../../selectors/list-settings'
import {getComponentConfig, isRibbonVisible} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {connect} from '../../runtime-context/connect'
import {Card as CardPresentation} from './card'
import {CardOwnProps, CardStateProps} from './interfaces'

const mapRuntime = ({state, host: {dimensions}}: AppProps, {event}: CardOwnProps): CardStateProps => {
  const componentConfig = getComponentConfig(state)

  return {
    showImage: isImageVisible(state),
    showDate: isDateVisible(state),
    showVenue: isVenueVisible(state, dimensions),
    showFullDate: isFullDateVisible(state),
    borderWidth: getCardBorderWidth(componentConfig),
    showLocation: isLocationVisible(state),
    showDescription: isDescriptionVisible(state),
    showSocialBar: isSocialShareVisible(state),
    showMembers: isMembersVisible(state, event),
    membersEnabled: isMembersEnabled(state),
    hideAdditionalComponents: isAdditionalComponentsHidden(state),
    imageRatio: getImageRatio(getComponentConfig(state)),
    imageOpacity: getListImageOpacity(componentConfig),
    alignment: getListAlignment(componentConfig),
    cardLocationAndDateFormat: getcardLocationAndDateFormat(componentConfig),
    fullLocale: getFullLocale(state),
    hasRibbon: getRibbonData(state, event).visible,
    showRibbon: isRibbonVisible(state, event),
    editor: isEditor(state),
    preview: isPreview(state),
    expanded: isItemOpened(state, event.id),
    cardMargins: getCardMargins(componentConfig),
    compId: state.component.id,
  }
}

export const Card = connect<CardOwnProps, CardStateProps>(mapRuntime)(CardPresentation)
export * from './interfaces'
