import * as classNames from 'classnames'
import {Moment} from '@wix/wix-events-commons-statics'
import * as React from 'react'
import * as s from './calendar-cell.scss'
import {DayEvents} from './day-events'
import {CalendarCellProps, CalendarCellState} from '.'

export class CalendarCell extends React.PureComponent<CalendarCellProps, CalendarCellState> {
  state = {
    hover: false,
  }

  registerContainerRef = ref => {
    const {registerContainerRef, week, weekDay} = this.props
    registerContainerRef(week, weekDay, ref)
  }

  openPopup = (date: Moment, events: wix.events.Event[]) => {
    if (events.length === 1) {
      this.props.openMonthlyCalendarEvent(date.valueOf(), events[0].id)
    } else {
      this.props.openMonthlyCalendarPopup(date.valueOf())
    }
  }

  handleMouseOver = () => {
    this.setState({hover: true})
  }

  handleMouseOut = () => {
    this.setState({hover: false})
  }

  handleCellClick = () => {
    const {
      day: {date, events},
      closeMonthlyCalendarPopup,
    } = this.props

    events.length ? this.openPopup(date, events) : closeMonthlyCalendarPopup()
  }

  handleKeyPress = event => {
    if (event.key === 'Enter') {
      this.handleCellClick()
    }
  }

  render() {
    const {
      selectionExists,
      active,
      borderStyle,
      otherMonthBackground,
      actionBackground,
      day: {day, past, isCurrentMonth, today, events},
    } = this.props

    const useActionBackground = events.length && (active || this.state.hover)

    const style = {
      ...((!today || selectionExists) && !active ? borderStyle : {}),
      ...((!isCurrentMonth || past) && !useActionBackground ? otherMonthBackground : {}),
      ...(useActionBackground ? actionBackground : {}),
    }

    return (
      <div
        className={classNames(s.cell, {
          [s.today]: today && !selectionExists,
          [s.withEvents]: events.length,
          [s.active]: active,
        })}
        style={style}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
        onClick={this.handleCellClick}
        onKeyPress={this.handleKeyPress}
        tabIndex={events.length ? 0 : null}
        data-hook={isCurrentMonth ? `calendar-day-${day}` : `calendar-other-month-day-${day}`}
      >
        <div ref={this.registerContainerRef} className={s.content}>
          <div
            className={classNames(s.day, {[s.otherMonth]: !isCurrentMonth, [s.today]: today})}
            data-hook={isCurrentMonth ? `day-of-month-${day}` : `day-of-other-month-${day}`}
          >
            {day}
          </div>
          <DayEvents events={events} />
        </div>
        <div className={s.border} />
      </div>
    )
  }
}
