import * as React from 'react'
import {resizeComponent, setHeight} from '../../../commons/utils/wix-sdk'
import {DH} from '../../utils/data-hooks'
import {Title} from '../list-title'
import {Card} from './card'
import * as s from './cards.scss'
import {CardsProps} from '.'

const CARD_MAX_WIDTH = 500
const CARD_MIN_WIDTH = 200

export class Cards extends React.Component<CardsProps> {
  private container = React.createRef<HTMLDivElement>()

  componentDidMount() {
    if (this.props.editor) {
      this.resizeComponent()
    }
  }

  componentDidUpdate() {
    if (this.props.editor) {
      this.resizeComponent()
    }
  }

  resizeComponent = () => {
    const containerWidth = this.container.current.clientWidth || 0
    const cardsMinWidth = this.getMinWidth()

    if (containerWidth && containerWidth <= cardsMinWidth) {
      resizeComponent({width: cardsMinWidth})
        .catch(() => null)
        .then(() => this.setContainerHeight())
    } else {
      this.setContainerHeight()
    }
  }

  getMinWidth = () => {
    const {columns, cardMargins, responsive} = this.props
    const cardWithMargins = CARD_MIN_WIDTH + cardMargins
    if (responsive) {
      return cardWithMargins + cardMargins
    }
    return cardWithMargins * columns + cardMargins
  }

  getMaxWidth = () => {
    const {columns} = this.props

    return columns * CARD_MAX_WIDTH
  }

  setContainerHeight = () => {
    setHeight(this.container.current.clientHeight)
  }

  render() {
    const {events, t, cardMargins, columns} = this.props

    return (
      <div
        ref={this.container}
        className={s.container}
        style={{minWidth: this.getMinWidth(), maxWidth: this.getMaxWidth()}}
      >
        <Title />
        <div className={s.cards} data-hook={DH.cards} style={{padding: cardMargins / 2}}>
          {events.map(event => (
            <Card
              event={event}
              t={t}
              columns={columns}
              key={event.id}
              minWidth={CARD_MIN_WIDTH}
              maxWidth={CARD_MAX_WIDTH}
            />
          ))}
        </div>
      </div>
    )
  }
}
