import * as React from 'react'
import {DynamicStyleProps} from '.'

export const DynamicStyle: React.FC<DynamicStyleProps> = ({children, namespace}) => {
  let styles = children.toString()

  if (namespace) {
    styles = styles.replace(/^\s*\./gm, `.${namespace} .`)
    styles = styles.replace(/^\s*&\./gm, `.${namespace}.`)
  }

  return (
    <style
      dangerouslySetInnerHTML={{
        __html: styles,
      }}
    />
  )
}
